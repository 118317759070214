<template>
    <header class="fixed-top w-100">
      <!-- Desktop Navbar se agrega la clase navbar-scrolled si scrolled o si un dropdown esta abierto -->
      <nav class="navbar navbar-expand-lg px-3 py-2" :class="{ 'navbar-scrolled': scrolled || startScrolled || showComprarDropdown || showVenderDropdown || showAlquilarDropdown || isMenuOpen || startScrolled }"> 
        <div class="container">
          <a href="/demo" class="navbar-brand py-2 fw-bold fs-4" :class="{ 'text-primary': scrolled || startScrolled }">
            <i class="fa fa-home"></i>
            Tu empresa
          </a>
  
          <!-- Desktop Menu -->
          <div class="d-none d-lg-flex align-items-center justify-content-between flex-grow-1">
            <ul class="navbar-nav mx-auto">
              <li class="nav-item">
                <a href="/demo" class="nav-link">Inicio</a>
              </li>
              
              <!-- Comprar Dropdown -->
              <li class="nav-item dropdown">
                <a 
                  class="nav-link dropdown-toggle" 
                  href="/demo/propiedades" 
                  @mouseenter="showComprarDropdown = true"
                  @mouseleave="showComprarDropdown = false"
                >
                  Comprar
                  <i class="fas fa-chevron-down ms-2"></i>
                </a>
                <div 
                  class="dropdown-menu" 
                  :class="{ 'show': showComprarDropdown }"
                  @mouseenter="showComprarDropdown = true"
                  @mouseleave="showComprarDropdown = false"
                >
                    <a href="/demo/propiedades" class="dropdown-item">Casas</a>
                    <a href="/demo/propiedades" class="dropdown-item">Departamentos</a>
                    <a href="/demo/propiedades" class="dropdown-item">Terrenos</a>
                </div>
              </li>

              <!-- Vender Dropdown -->
              <li class="nav-item dropdown">
                <a 
                  class="nav-link dropdown-toggle" 
                  href="/demo/propiedades" 
                  @mouseenter="showVenderDropdown = true"
                  @mouseleave="showVenderDropdown = false"
                >
                  Vender
                  <i class="fas fa-chevron-down ms-2"></i>
                </a>
                <div 
                  class="dropdown-menu" 
                  :class="{ 'show': showVenderDropdown }"
                  @mouseenter="showVenderDropdown = true"
                  @mouseleave="showVenderDropdown = false"
                >
                    <a href="/demo/propiedades" class="dropdown-item">Tasación</a>
                    <a href="/demo/propiedades" class="dropdown-item">Publicar Propiedad</a>
                    <a href="/demo/propiedades" class="dropdown-item">Guía de Venta</a>
                </div>
              </li>

              <!-- Alquilar Dropdown -->
              <li class="nav-item dropdown">
                <a 
                  class="nav-link dropdown-toggle" 
                  href="/demo/propiedades" 
                  @mouseenter="showAlquilarDropdown = true"
                  @mouseleave="showAlquilarDropdown = false"
                >
                  Alquilar
                  <i class="fas fa-chevron-down ms-2"></i>
                </a>
                <div 
                  class="dropdown-menu" 
                  :class="{ 'show': showAlquilarDropdown }"
                  @mouseenter="showAlquilarDropdown = true"
                  @mouseleave="showAlquilarDropdown = false"
                >
                    <a href="/demo/propiedades" class="dropdown-item">Casas en Alquiler</a>
                    <a href="/demo/propiedades" class="dropdown-item">Departamentos en Alquiler</a>
                    <a href="/demo/propiedades" class="dropdown-item">Requisitos de Alquiler</a>
                </div>
              </li>
            </ul>
            <a class="btn btn-primary" href="mailto:propmind@proton.me">
              Contáctanos</a>
          </div>
  
          <!-- Mobile Menu Button -->
          <button 
            class="navbar-toggler d-lg-none" 
            type="button" 
            @click="toggleMenu" 
            :aria-expanded="isMenuOpen"
            aria-label="Toggle navigation"
          >
            <span class="hamburger" :class="{ 'is-active': isMenuOpen }">
              <span class="hamburger-line"></span>
              <span class="hamburger-line"></span>
              <span class="hamburger-line"></span>
            </span>
          </button>
        </div>
      </nav>
  
      <!-- Mobile Menu -->
      <div 
        class="mobile-menu" 
        :class="{ 'is-open': isMenuOpen }"
        @click.self="closeMenu"
      >
        <div class="mobile-menu-container">
          <ul class="nav flex-column">
            <li class="nav-item">
              <a @click="closeMenu" href="/" class="nav-link">Inicio</a>
            </li>
            
            <!-- Mobile Comprar Submenu -->
            <li class="nav-item">
              <a class="nav-link" @click="toggleComprarDropdownMobile">
                Comprar
                <span class="float-end" :class="{ 'rotated': isComprarDropdownOpen }">▼</span>
              </a>
              <div 
                class="submenu" 
                :class="{ 'show': isComprarDropdownOpen }"
              >
                <a @click="closeMenu" href="/demo/propiedades" class="nav-link">Casas</a>
                <a @click="closeMenu" href="/demo/propiedades" class="nav-link">Departamentos</a>
                <a @click="closeMenu" href="/demo/propiedades" class="nav-link">Terrenos</a>
              </div>
            </li>

            <!-- Mobile Vender Submenu -->
            <li class="nav-item">
              <a class="nav-link" @click="toggleVenderDropdownMobile">
                Vender
                <span class="float-end" :class="{ 'rotated': isVenderDropdownOpen }">▼</span>
              </a>
              <div 
                class="submenu" 
                :class="{ 'show': isVenderDropdownOpen }"
              >
                <a @click="closeMenu" href="/demo/propiedades" class="nav-link">Tasación</a>
                <a @click="closeMenu" href="/demo/propiedades" class="nav-link">Publicar Propiedad</a>
                <a @click="closeMenu" href="/demo/propiedades" class="nav-link">Guía de Venta</a>
              </div>
            </li>

            <!-- Mobile Alquilar Submenu -->
            <li class="nav-item">
              <a class="nav-link" @click="toggleAlquilarDropdownMobile">
                Alquilar
                <span class="float-end" :class="{ 'rotated': isAlquilarDropdownOpen }">▼</span>
              </a>
              <div 
                class="submenu" 
                :class="{ 'show': isAlquilarDropdownOpen }"
              >
                <a @click="closeMenu" href="/demo/propiedades" class="nav-link">Casas en Alquiler</a>
                <a @click="closeMenu" href="/demo/propiedades" class="nav-link">Departamentos en Alquiler</a>
                <a @click="closeMenu" href="/demo/propiedades" class="nav-link">Requisitos de Alquiler</a>
              </div>
            </li>

            <li class="nav-item">
              <a @click="closeMenu" href="/blog" class="nav-link">Blog</a>
            </li>
          </ul>
          <a class="btn btn-primary w-100 mt-4" href="mailto:propmind@proton.me">Contáctanos</a>
        </div>
      </div>
    </header>
  </template>
  
  <script>
  export default {
    name: 'AppNavbar',
    data() {
      return {
        isMenuOpen: false,
        scrolled: false,
        // Desktop dropdown states
        showComprarDropdown: false,
        showVenderDropdown: false,
        showAlquilarDropdown: false,
        
        // Mobile dropdown states
        isComprarDropdownOpen: false,
        isVenderDropdownOpen: false,
        isAlquilarDropdownOpen: false
      }
    },
    props: {
      startScrolled: {
        type: Boolean,
        default: false
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll)
      window.addEventListener('keydown', this.handleEscKey)
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.handleScroll)
      window.removeEventListener('keydown', this.handleEscKey)
    },
    methods: {
      handleScroll() {
        this.scrolled = window.scrollY > 50
      },
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen
        document.body.style.overflow = this.isMenuOpen ? 'hidden' : ''
      },
      closeMenu() {
        if (this.isMenuOpen) {
          this.isMenuOpen = false
          // Reset all mobile dropdown states
          this.isComprarDropdownOpen = false
          this.isVenderDropdownOpen = false
          this.isAlquilarDropdownOpen = false
          document.body.style.overflow = ''
        }
      },
      handleEscKey(e) {
        if (e.key === 'Escape') this.closeMenu()
      },
      toggleComprarDropdownMobile() {
        this.isComprarDropdownOpen = !this.isComprarDropdownOpen
        // Close other dropdowns
        this.isVenderDropdownOpen = false
        this.isAlquilarDropdownOpen = false
      },
      toggleVenderDropdownMobile() {
        this.isVenderDropdownOpen = !this.isVenderDropdownOpen
        // Close other dropdowns
        this.isComprarDropdownOpen = false
        this.isAlquilarDropdownOpen = false
      },
      toggleAlquilarDropdownMobile() {
        this.isAlquilarDropdownOpen = !this.isAlquilarDropdownOpen
        // Close other dropdowns
        this.isComprarDropdownOpen = false
        this.isVenderDropdownOpen = false
      }
    }
  }
  </script>
  
  <style scoped>
  .navbar {
  padding: 1rem 0;
  transition: all 0.3s ease;
  }
  
  .navbar-scrolled {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  }

  .navbar-brand{
    color: var(--white-color);
  }

  .navbar-scrolled .nav-link, .navbar-scrolled .navbar-brand {
    color: var(--black-color);
  }

  
  .logo {
  transition: height 0.3s ease;
  }
  
  .nav-link {
  position: relative;
  padding: 0.5rem 1rem;
  color: var(--white-color);
  font-weight: 500;
  transition: color 0.3s ease;
  margin: 0 0.765rem;
  }
  
  .nav-link:hover,
  .nav-link.router-link-active {
  color: var(--primary-color);
  }

  
  .nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 1rem;
  right: 1rem;
  height: 2px;
  background: var(--primary-color);
  transform: scaleX(0);
  transition: transform 0.3s ease;
  }
  
  .nav-link:hover::after,
  .nav-link.router-link-active::after {
  transform: scaleX(1);
  }
  
  /* Dropdown Styles */
  .dropdown-menu {
  display: block;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease;
  margin-top: 0;
  border-radius: 8px;
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  }
  
  .dropdown-item {
  padding: 0.75rem 1.5rem;
  color: var(--dark-color);
  transition: all 0.3s ease;
  }
  
  .dropdown-item:hover {
  background-color: var(--light-color);
  color: var(--primary-color);
  }
  
  /* Mobile Menu Styles */
  .mobile-menu.active {
  outline: none;
  box-shadow: none;
  }
  
  .hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  border: none;
  background: none;
  padding: 0;
  }

  .navbar-scrolled .hamburger-line {
    background: var(--black-color);
  }
  
  .hamburger-line {
  display: block;
  width: 30px;
  height: 3px;
  border-radius: 5px;
  background: var(--white-color);
  transition: all 0.3s ease;
  }
  
  .hamburger.is-active .hamburger-line:nth-child(1) {
  transform: translateY(11px) rotate(45deg);
  }
  
  .hamburger.is-active .hamburger-line:nth-child(2) {
  opacity: 0;
  }
  
  .hamburger.is-active .hamburger-line:nth-child(3) {
  transform: translateY(-11px) rotate(-45deg);
  }
  
  .mobile-menu {
  position: fixed;
  top: 73px;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  }
  
  .mobile-menu.is-open {
  visibility: visible;
  opacity: 1;
  }
  
  .mobile-menu-container {
  background: white;
  padding: 2rem;
  transform: translateY(-100%);
  transition: transform 0.3s ease;
  }
  
  .mobile-menu.is-open .mobile-menu-container {
  transform: translateY(0);
  }
  
  .mobile-menu .nav-link {
  font-size: 1.25rem;
  padding: 1rem 0;
  color: var(--black-color);
  }
  
  /* Mobile Submenu Styles */
  .submenu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding-left: 1rem;
  }
  
  .submenu.show {
  max-height: 500px;
  }
  
  .submenu .nav-link {
  font-size: 1.1rem;
  padding: 0.5rem 0;
  }
  
  .rotated {
  display: inline-block;
  transform: rotate(180deg);
  transition: transform 0.3s ease;
  }
  
  @media (max-width: 991.98px) {
  .navbar-toggler {
    padding: 0;
    border: none;
  }
  }
  </style>