<template>
    <section class="cta-section py-5 mx-2">
        <div class="container">
          <div class="cta-wrapper bg-primary position-relative overflow-hidden">
            <div class="row align-items-center">
              <div class="col-lg-6 py-5 px-4 px-lg-5 text-center text-lg-start">
                <h2 class="display-4 fw-bold text-white mb-4">
                  Unite al futuro de la gestión inmobiliaria
                </h2>
                <p class="lead text-white-75 mb-4">
                  Comienza a gestionar tus propiedades y leads de forma eficiente.
                  Incrementa tus ventas y optimiza tu tiempo.
                </p>
                <a href="mailto:soporte@propmind.com.ar" class="btn btn-light btn-lg rounded-pill px-4 py-3 fw-semibold">
                  <i class="fas fa-rocket me-2"></i>Contratar Ahora
                </a>
              </div>
              <div class="col-lg-6 d-none d-lg-block">
                <div class="cta-image-wrapper">
                  <img src="@/assets/images/crm_screenshots/property-list.png" alt="Dashboard Preview" class="cta-image img-fluid">
                </div>
              </div>
            </div>
            
            <!-- Decorative elements -->
            <div class="decoration-circle decoration-circle-1"></div>
            <div class="decoration-circle decoration-circle-2"></div>
          </div>
        </div>
      </section>
</template>

<script>
export default {
  name: 'CTABanner'
}
</script>

<style scoped>
.cta-section {
  background-color: #f8f9fa;
}

.cta-wrapper {
  border-radius: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}


.cta-image-wrapper {
  position: relative;
  height: 100%;
  min-height: 300px;
}

.cta-image {
  position: absolute;
  top: 50%;
  right: -10%;
  transform: translateY(-50%);
  max-width: 115%;
  height: auto;
  border-radius: 1rem;
}

.decoration-circle {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}

.decoration-circle-1 {
  width: 150px;
  height: 150px;
  top: -75px;
  left: -75px;
}

.decoration-circle-2 {
  width: 100px;
  height: 100px;
  bottom: -50px;
  right: 10%;
}

@media (max-width: 991.98px) {
  .cta-wrapper {
    text-align: center;
  }
}
</style>