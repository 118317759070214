<template>
  
  <AppNavbar />
  <div class="terms-conditions bg-light pt-5">
    <!-- Encabezado -->
    <div class="container py-4">
      <div class="row justify-content-center">
        <div class="col-lg-8 text-center">
          <h1 class="mb-3 mt-5">Términos y Condiciones</h1>
          <p class="text-muted">Última actualización: {{ lastUpdate }}</p>
          <div class="alert alert-warning d-inline-block">
            <i class="fas fa-info-circle me-2"></i>
            Por favor, lee detenidamente estos términos antes de usar nuestros servicios
          </div>
        </div>
      </div>
    </div>

    <!-- Contenido Principal -->
    <div class="container pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <!-- Índice rápido -->
          <div class="card mb-4">
            <div class="card-body">
              <h2 class="h5 mb-3">Contenido</h2>
              <div class="d-flex flex-wrap gap-2">
                <button 
                  v-for="section in sections" 
                  :key="section.id"
                  class="btn btn-outline-primary btn-sm"
                  @click="scrollToSection(section.id)"
                >
                  {{ section.title }}
                </button>
              </div>
            </div>
          </div>

          <!-- Secciones de Términos -->
          <div v-for="section in sections" 
               :key="section.id"
               :id="section.id" 
               class="mb-4">
            <div class="card">
              <div class="card-body p-4">
                <h2 class="h4 mb-3 d-flex align-items-center">
                  <i :class="section.icon + ' me-2 text-primary'"></i>
                  {{ section.title }}
                </h2>

                <div class="terms-content">
                  <p>{{ section.description }}</p>
                  
                  <ul class="list-unstyled mt-3">
                    <li v-for="(item, index) in section.items" 
                        :key="index" 
                        class="mb-3">
                      <div class="d-flex">
                        <i class="fas fa-chevron-right text-primary me-2 mt-1"></i>
                        <span>{{ item }}</span>
                      </div>
                    </li>
                  </ul>

                  <div v-if="section.note" class="alert alert-light mt-3">
                    <i class="fas fa-exclamation-circle me-2"></i>
                    {{ section.note }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Aceptación de Términos -->
          <div class="card mt-5">
            <div class="card-body p-4 text-center">
              <h3 class="h5 mb-3">¿Tienes preguntas sobre nuestros términos?</h3>
              <div class="d-flex justify-content-center gap-3">
                <a href="mailto:legal@ejemplo.com" class="btn btn-primary">
                  <i class="fas fa-envelope me-2"></i>
                  Contactar
                </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <AppFooter />
</template>

<script>
import AppNavbar from '@/components/Navbar.vue'
import AppFooter from '@/components/Footer.vue'
export default {
  name: 'TermsAndConditions',
  components: {
    AppNavbar,
    AppFooter
  },
  data() {
    return {
      lastUpdate: '12 de Noviembre, 2024',
      sections: [
        {
          id: 'general',
          title: 'Términos Generales',
          icon: 'fas fa-file-contract',
          description: 'Al acceder y utilizar nuestra plataforma inmobiliaria, aceptas los siguientes términos y condiciones:',
          items: [
            'Debes tener al menos 18 años para usar nuestros servicios.',
            'La información proporcionada debe ser precisa y verdadera.',
            'Te comprometes a mantener la confidencialidad de tu cuenta.',
            'Nos reservamos el derecho de modificar o terminar el servicio en cualquier momento.',
            'El uso inadecuado del servicio puede resultar en la terminación de tu cuenta.'
          ]
        },
        {
          id: 'services',
          title: 'Servicios Inmobiliarios',
          icon: 'fas fa-home',
          description: 'Nuestros servicios inmobiliarios están sujetos a las siguientes condiciones:',
          items: [
            'Las propiedades listadas están sujetas a disponibilidad y cambios.',
            'Las imágenes y descripciones deben representar fielmente las propiedades.',
            'Las transacciones deben realizarse a través de los canales oficiales.',
            'Los agentes inmobiliarios deben estar debidamente certificados.',
            'Las comisiones y tarifas serán especificadas antes de cada transacción.'
          ],
          note: 'No nos hacemos responsables por transacciones realizadas fuera de nuestra plataforma.'
        },
        {
          id: 'user-responsibilities',
          title: 'Responsabilidades del Usuario',
          icon: 'fas fa-user-check',
          description: 'Como usuario de nuestra plataforma, te comprometes a:',
          items: [
            'No publicar información falsa o engañosa sobre propiedades.',
            'Respetar los derechos de propiedad intelectual.',
            'No realizar actividades fraudulentas o maliciosas.',
            'Mantener actualizada tu información de contacto.',
            'Reportar cualquier actividad sospechosa o irregular.'
          ]
        },
        {
          id: 'intellectual-property',
          title: 'Propiedad Intelectual',
          icon: 'fas fa-copyright',
          description: 'Todos los derechos de propiedad intelectual están protegidos:',
          items: [
            'El contenido de la plataforma está protegido por derechos de autor.',
            'Las marcas y logotipos son propiedad exclusiva nuestra.',
            'No está permitida la reproducción sin autorización.',
            'Los usuarios mantienen los derechos de sus propias publicaciones.',
            'El uso indebido de material protegido será sancionado.'
          ]
        },
        {
          id: 'liability',
          title: 'Limitación de Responsabilidad',
          icon: 'fas fa-shield-alt',
          description: 'Nuestra responsabilidad está limitada en los siguientes aspectos:',
          items: [
            'No garantizamos la disponibilidad continua del servicio.',
            'No somos responsables de la precisión de la información proporcionada por usuarios.',
            'No nos hacemos cargo de pérdidas derivadas del uso del servicio.',
            'Las disputas entre usuarios deben resolverse entre las partes involucradas.',
            'Nos reservamos el derecho de intervenir en casos de conflicto.'
          ]
        },
        {
          id: 'modifications',
          title: 'Modificaciones',
          icon: 'fas fa-edit',
          description: 'Sobre los cambios en estos términos:',
          items: [
            'Podemos modificar estos términos en cualquier momento.',
            'Los cambios serán notificados a través de la plataforma.',
            'El uso continuado implica la aceptación de los nuevos términos.',
            'Los usuarios pueden terminar su uso si no aceptan los cambios.'
          ]
        }
      ]
    }
  },
  methods: {
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },
    downloadTerms() {
      // Implementar la lógica de descarga del documento
      alert('Descarga de términos y condiciones iniciada...')
    }
  }
}
</script>

<style scoped>
.terms-conditions {
  min-height: 100vh;
}

.card {
  border: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: transform 0.2s ease;
}

.card:hover {
  transform: translateY(-2px);
}

.terms-content p {
  line-height: 1.6;
}

.btn-sm {
  padding: 0.25rem 0.75rem;
}

@media (max-width: 768px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .card-body {
    padding: 1.5rem;
  }
}
</style>