<template>
  <div class="container">
    <div class="row justify-content-center my-5">
      <div class="card py-3">
        <div class="card-body">
          <h2 class="text-center py-3" v-if="title">{{ title }}</h2>
          <form @submit.prevent="sendMessage" class="d-flex row">
            <div class="my-3 col-lg-6">
              <label for="name" class="form-label">Nombre completo<sup>*</sup></label>
              <input
                type="text"
                class="form-control"
                id="name"
                v-model="name"
                required
              />
            </div>
            <div class="my-3 col-lg-6">
              <label for="phone" class="form-label">Numero de teléfono<sup>*</sup></label>
              <input
                type="tel"
                class="form-control"
                id="phone"
                v-model="phone"
                required
              />
            </div>
            <div class="my-3">
              <label for="email" class="form-label">Email<sup>*</sup></label>
              <input
                type="email"
                class="form-control"
                id="email"
                v-model="email"
                required
              />
            </div>
            <div class="my-3">
              <label for="inquiry" class="form-label">
                ¿En qué podemos ayudarte?
              </label>
              <select
                class="form-select"
                id="inquiry"
                v-model="inquiry"
                required
              >
                <option value="">Selecciona una opción</option>
                <option v-for="option in inquiryOptions" :key="option">
                  {{ option }}
                </option>
              </select>
            </div>
            <div class="my-3">
              <label for="message" class="form-label"
                >Información adicional<sup>*</sup></label
              >
              <textarea
                class="form-control"
                id="message"
                rows="3"
                v-model="message"
                required
              ></textarea>
            </div>
            <div class="d-flex">
                <button type="submit" class="btn btn-primary py-2 px-4">
                  Enviar mensaje
                  <i class="fas fa-paper-plane ms-2"></i>
                </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactForm",
  components: {},
  props: {
    inquiryOptions: {
      type: Array,
      default: () => ['Información general', 'Soporte técnico', 'Ventas', 'Otro'],
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      inquiry: "",
      message: "",
    };
  },
  methods: {
    sendMessage() {
      // Add your logic to send the message here
      console.log("Message sent:", {
        name: this.name,
        phone: this.phone,
        email: this.email,
        inquiry: this.inquiry,
        message: this.message,
      });
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.form-control,
.form-select {
  border-radius: 4px;
}

input {
  border: 1px solid #ced4da;
  padding: .45rem;
  background-color: var(--grey-color);
}

textarea {
  border: 1px solid #ced4da;
  padding: .45rem;
  background-color: var(--grey-color);
}
select {
  border: 1px solid #ced4da;
  padding: .45rem;
  background-color: var(--grey-color);
}
sup{
    color: red;
}

.fa-envelope,
.fa-phone {
  margin-right: 8px;
}
</style>
