<template>
  <AppNavbar :startScrolled="true"/>
  <div class="property-detail container-fluid bg-light mt-5 pt-5">
    <div class="container py-5">
      <div class="row g-4">
        <!-- Image Gallery -->
        <div class="col-md-7">
          <div class="property-gallery position-relative">
            <div class="main-image-container mb-3">
              <img 
                :src="currentMainImage" 
                :alt="property.title" 
                class="img-fluid rounded-3 main-image shadow-lg"
              />
              <div class="image-overlay position-absolute top-0 start-0 p-3">
                <span class="badge bg-primary me-2">{{ property.listingType }}</span>
                <span class="badge bg-success">{{ property.status }}</span>
              </div>
            </div>
            
            <div class="thumbnail-gallery d-flex justify-content-center row">
              <div 
                v-for="(image, index) in property.additionalImages" 
                :key="index" 
                class="thumbnail col-lg-3 col-md-4 col-sm-6 col-3"
                @click="changeMainImage(image)"
              >
                <img :src="image" class="img-thumbnail rounded-3" />
              </div>
            </div>
          </div>
        </div>
  
        <!-- Property Details -->
        <div class="col-md-5">
          <div class="property-info bg-white p-lg-4 rounded-3 shadow-sm">
            <h1 class="property-title mb-3">{{ property.title }}</h1>
            <p class="property-address text-muted mb-3">
              <i class="fas fa-map-marker-alt me-2 text-primary"></i>
              {{ property.address }}
            </p>
  
            <div class="property-price mb-4">
              <h2 class="text-primary fw-bold">
                ${{ property.price.toLocaleString() }}
                <small class="text-muted fs-6 ms-2">
                  {{ property.priceType }}
                </small>
              </h2>
            </div>
  
            <div class="property-features bg-light p-3 rounded-3 mb-4">
              <div class="row g-3 text-center">
                <div class="col-4">
                  <i class="fas fa-bed text-primary fs-4"></i>
                  <p class="mb-0 mt-2 fw-bold">{{ property.bedrooms }}</p>
                  <small class="text-muted">Ambientes</small>
                </div>
                <div class="col-4">
                  <i class="fas fa-bath text-primary fs-4"></i>
                  <p class="mb-0 mt-2 fw-bold">{{ property.bathrooms }}</p>
                  <small class="text-muted">Baños</small>
                </div>
                <div class="col-4">
                  <i class="fas fa-vector-square text-primary fs-4"></i>
                  <p class="mb-0 mt-2 fw-bold">{{ property.squareMeters }}</p>
                  <small class="text-muted">m²</small>
                </div>
              </div>
            </div>
  
            <div class="property-description mb-4">
              <h4 class="mb-3">Descripción</h4>
              <p class="text-muted">{{ property.description }}</p>
            </div>
  
            <div class="property-actions d-flex">
              <button class="btn btn-primary me-2 flex-grow-1">
                <i class="fas fa-envelope me-2"></i>Contactar Agente
              </button>
              <button class="btn btn-outline-secondary flex-grow-1">
                <i class="fas fa-phone me-2"></i>Llamar
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Additional Details Section -->
      <div class="mt-5 pt-3 row d-flex gap-5">
        <div class="col-12">
          <h4 class="mb-4 pe-3 fw-bold" style="border-left: 5px solid var(--primary-color);">
            <span class="ms-3">Ubicación</span></h4>
            <p>
              Av. del Libertador 1234, Palermo, Buenos Aires
            </p>
            <div id="property-map" style="height: 400px; min-width: 100%;"></div>
        </div>
        <div class="col-12">
          <h4 class="mb-4 pe-3 fw-bold" style="border-left: 5px solid var(--primary-color);">
            <span class="ms-3">Características Especiales</span>
          </h4>
          <div class="row g-3">
            <div 
              v-for="(feature, index) in property.specialFeatures" 
              :key="index" 
              class="col-lg-2 col-6"
            >
              <div class="d-flex align-items-center">
                <i class='fa fa-check text-primary fs-5 me-2'></i>
                <p class="mb-0">{{ feature.name }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <h4 class="mb-4 pe-3 fw-bold" style="border-left: 5px solid var(--primary-color);">
            <span class="ms-3">Lugares Cercanos</span>
          </h4>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <i class="fas fa-check me-2 text-primary"></i>
              Escuelas Internacionales
            </li>
            <li class="list-group-item">
              <i class="fas fa-check me-2 text-primary"></i>
              Centros Comerciales de Primer Nivel
            </li>
            <li class="list-group-item">
              <i class="fas fa-check me-2 text-primary"></i>
              Parques Urbanos
            </li>
            <li class="list-group-item">
              <i class="fas fa-check me-2 text-primary"></i>
              Transporte Público Eficiente
            </li>
            <li class="list-group-item">
              <i class="fas fa-check me-2 text-primary"></i>
              Hospitales de Especialidades
            </li>
          </ul>
        </div>
      </div>
      <div class=" d-flex row justify-content-between pt-5">
        <div class="col-lg-6 d-flex justify-content-center">
          <ContactForm  :inquiryOptions="['Información General', 'Agendar una Visita', 'Otro']" :title="property.title" />
        </div>
        <div class="col-lg-6 d-flex align-items-center">
          <img src="./static/illustrations/message.svg" alt="message illustration" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
  <AppFooter />
</template>
  
<script>
import AppNavbar from './components/Navbar.vue'
import AppFooter from './components/Footer.vue'
import ContactForm from './components/ContactForm.vue'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

export default {
  name: 'DemoProperty',
  components: {
    AppNavbar,
    AppFooter,
    ContactForm
  },
  data() {
    return {
      currentMainImage: require('./static/images/place_holder_property.jpg'),
      property: {
        title: 'Espectacular Departamento Moderno en Palermo',
        address: 'Av. del Libertador 1234, Palermo, Buenos Aires',
        price: 250000,
        priceType: 'Venta',
        listingType: 'Exclusivo',
        status: 'Disponible',
        additionalImages: [
          require('./static/images/place_holder_property.jpg'),
          require('./static/images/place_holder_property.jpg'),
          require('./static/images/place_holder_property.jpg'),
          require('./static/images/place_holder_property.jpg')
        ],
        bedrooms: 3,
        bathrooms: 2,
        squareMeters: 150,
        description: 'Impresionante departamento moderno con diseño contemporáneo, ubicado en una de las zonas más exclusivas de Buenos Aires. Cuenta con acabados de lujo, amplios espacios, iluminación natural y vistas panorámicas. Ideal para familias que buscan confort y elegancia.',
        type: 'Departamento de Lujo',
        age: 2,
        parkingSpots: 2,
        nearbyServices: [
          'Escuelas Internacionales', 
          'Centros Comerciales de Primer Nivel', 
          'Parques Urbanos', 
          'Transporte Público Eficiente',
          'Hospitales de Especialidades'
        ],
        specialFeatures: [
          { name: 'Cocina Gourmet' },
          { name: 'Sistema Domotico' },
          { name: 'Gimnasio Privado' },
          { name: 'Terraza' },
          { name: 'Piscina Climatizada' }
        ]
      }
    }
  },
  methods: {
    changeMainImage(image) {
      this.currentMainImage = image
    },
    // ... existing methods ...
    initMap() {
      // Coordinates for the property (Buenos Aires example)
      const propertyCoords = [-34.583722, -58.381592]
      
      // Create map instance
      const map = L.map('property-map').setView(propertyCoords, 13)
      
      // Add OpenStreetMap tiles
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors'
      }).addTo(map)

      let propertyIcon = L.icon({
        iconUrl: require('./static/marker-icon.svg'),
        iconSize: [35, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      })

      // Add marker
      L.marker(propertyCoords, { icon: propertyIcon }).addTo(map)
        .bindPopup('<b>Propiedad en Venta</b><br>Av. del Libertador 1234, Palermo, Buenos Aires')
        .openPopup()
    }
  },
  mounted() {
    this.initMap()
  }
}
</script>
  
<style scoped>
.property-detail {
  font-family: 'Roboto', sans-serif;
}

.main-image {
  max-height: 500px;
  width: 100%;
  object-fit: cover;
}

.thumbnail-gallery .thumbnail {
  cursor: pointer;
  object-fit: cover;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.thumbnail-gallery .thumbnail:hover {
  opacity: 1;
  transform: scale(1.05);
}

.property-features .col-4 {
  transition: transform 0.3s ease;
}

.property-features .col-4:hover {
  transform: translateY(-5px);
}

.list-group-item {
  background-color: transparent;
}
</style>