// src/router.js
import { createRouter, createWebHistory } from "vue-router";
import AppHome from "@/pages/Home.vue";
import WhatsappIntegration from "@/pages/Whatsapp.vue";
import PricesSection from "@/pages/Precios.vue";
import PortalsIntegration from "@/pages/Portals.vue";
import GoogleCalendar from "@/pages/GoogleCalendar.vue";

// demo
import DemoHome from "@/pages/demo/Home.vue";
import DemoProperty from "@/pages/demo/Property.vue";
import DemoPropertiesSearch from "@/pages/demo/PropertiesSearch.vue";

import PrivacySection from "@/pages/Privacy.vue";
import TermsOfService from "@/pages/TermsOfService.vue";

const routes = [
  { path: "/", name: "Home", component: AppHome },
  { path: "/precios", name: "prices", component: PricesSection },
  { path: "/integraciones/whatsapp", name: "whatsapp-integration", component: WhatsappIntegration },

  { path: "/integraciones/portales-inmobiliarios", name: "portals-integration", component: PortalsIntegration },
  { path: "/integraciones/google-calendar", name: "calendar-integration", component: GoogleCalendar },
  
  {path: "/privacidad-y-cookies", name: "Privacy", component: PrivacySection},
  {path: "/terminos-y-condiciones", name: "TOS", component: TermsOfService},

  // demo
  {path: "/demo", name: "DemoHome", component: DemoHome},
  {path: "/demo/propiedad-de-ejemplo", name: "DemoProperty", component: DemoProperty},
  {path: "/demo/propiedades", name: "DemoPropertySearch", component: DemoPropertiesSearch},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
