<template>
  <a href="/demo/propiedad-de-ejemplo/" class="text-decoration-none property-card card border-0 shadow-sm my-4 position-relative">
    <div class="card-image-container position-relative overflow-hidden">
      <img
        src="../static/images/place_holder_property.jpg"
        class="card-img-top property-image"
        :alt="property.address"
        loading="lazy"
      />
      <div class="image-overlay position-absolute top-0 right-0 m-1 w-100 h-100">
        <span class="badge bg-primary">{{ property.type }}</span>
      </div>
    </div>
    
    <div class="card-body px-3 py-3">
      <div class="d-flex justify-content-between align-items-start mb-2">
        <h5 class="card-title mb-0 me-2 text-truncate">
          <i class="fas fa-map-marker-alt text-muted me-2"></i>
          {{ property.address }}
        </h5>
        <span class="badge bg-success rounded-pill">
          <i class="fas fa-tags me-1"></i>
          {{ property.status }}
        </span>
      </div>
      
      <p class="card-text text-muted mb-3 property-description">
        <i class="fas fa-align-left me-1"></i>
        {{ truncateDescription(property.description) }}
      </p>
      
      <div class="d-flex justify-content-between align-items-center">
        <div class="property-price">
          <span class="h5 text-primary mb-0 fw-bold">
            <i class="fas fa-dollar-sign me-1"></i>
            {{ formatPrice(property.price) }}
          </span>
        </div>
        
        <div class="property-actions">
          <button 
            @click.prevent="toggleFavorite" 
            class="btn btn-light btn-sm me-2 favorite-button"
            :class="{ 'is-favorite': isFavorite }"
          >
            <i 
              class="heart-icon" 
              :class="isFavorite ? 'fas fa-heart text-danger' : 'far fa-heart'"
            ></i>
          </button>
        </div>
      </div>
    </div>
    
    <div class="card-footer bg-transparent border-0 pt-0">
      <div class="d-flex justify-content-between small text-muted">
        <span class="fs-7">
          <i class="fas fa-bed me-1"></i> 
          {{ property.bedrooms }} Ambientes
        </span>
        <span class="fs-7">
          <i class="fas fa-bath me-1"></i> 
          {{ property.bathrooms }} Baños
        </span>
        <span class="fs-7">
          <i class="fas fa-ruler me-1"></i> 
          {{ property.area }} m<sup>2</sup>
        </span>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: "PropertyCard",
  props: {
    property: {
      type: Object,
      required: true,
      validator: (prop) => {
        return prop.address && prop.price;
      }
    }
  },
  data() {
    return {
      isFavorite: false
    }
  },
  methods: {
    truncateDescription(description, maxLength = 100) {
      return description.length > maxLength 
        ? description.substr(0, maxLength) + '...' 
        : description;
    },
    formatPrice(price) {
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(price);
    },
    toggleFavorite() {
      this.isFavorite = !this.isFavorite;
      
      // Emitir evento para que el padre sepa que se modificó favorito
      this.$emit('favorite-toggle', {
        propertyId: this.property.id,
        isFavorite: this.isFavorite
      });
    },
    viewDetails() {
      this.$emit('view-details', this.property.id);
    }
  }
}
</script>

<style scoped>
.property-card {
  transition: all 0.3s ease;
  overflow: hidden; 
}

.property-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.12) !important;
}

.card-image-container {
  height: 230px;
}

.property-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.property-card:hover .property-image {
  transform: scale(1.05);
}

.image-overlay {
  background: linear-gradient(to bottom, rgba(0,0,0,0.1), transparent 50%);
}

.property-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.favorite-button {
  position: relative;
  background: none;
  border: none;
}

.heart-icon {
  font-size: 1.4rem;
  transition: transform 0.3s ease, color 0.3s ease;
}

.favorite-button:hover .heart-icon {
  transform: scale(1.2);
}

.is-favorite .heart-icon {
  animation: heartbeat 0.5s ease;
}

@keyframes heartbeat {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.3); }
}
</style>