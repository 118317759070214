<template>
  <footer class="container-fluid bg-primary text-white py-5">
    <div class="container">
      <div class="row d-flex justify-content-between">
        <!-- Properties Section -->
        <!-- Contact and Social Section -->
        <div class="col-12 col-md-2">
          <div class="d-flex align-items-center justify-content-lg-start justify-content-center mb-5">
            <span class="h4 mb-0 me-3 fw-bold fs-2">TU LOGO</span>
          </div>
          <div class="social-links d-flex">
            <a href="#" class="social-icon">
              <i class="fab fa-facebook-f me-2"></i>
            </a>
            <a href="#" class="social-icon">
              <i class="fab fa-instagram me-2"></i>
            </a>
            <a href="#" class="social-icon">
              <i class="fab fa-twitter me-2"></i>
            </a>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <h5 class="footer-title">Navegación</h5>
          <div class="row g-2">
            <div v-for="nav in navigation" :key="nav" class="col-12">
              <a href="#" class="footer-link">
                {{ nav }}
              </a>
            </div>
          </div>
        </div>

        <!-- Rentals Section -->
        <div class="col-12 col-md-3">
          <h5 class="footer-title">Empresa</h5>
          <div class="row g-2">
            <div v-for="comp in company" :key="comp" class="col-12">
              <a href="#" class="footer-link">
                {{ comp }}
              </a>
            </div>
          </div>
        </div>

        <!-- Purchases Section -->
        <div class="col-12 col-md-3">
          <h5 class="footer-title">Soporte</h5>
          <div class="row g-2">
            <div v-for="sup in Soporte" :key="sup" class="col-12">
              <a href="#" class="footer-link">
                {{ sup }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- Bottom Bar -->
      <div class="text-white-50 mt-4 pt-3 mt-5 text-lg-start text-center">
        <small
          >© {{ currentYear }} TU EMPRESA. Todos los derechos reservados.</small
        >
        <br />
        <small>
          Diseñado por
          <a href="https://propmind.com.ar" class="text-white fs-6">
            Propmind
          </a>
        </small>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "ImprovedFooter",
  setup() {
    const currentYear = new Date().getFullYear();

    return {
      currentYear,
    };
  },
  data() {
    return {
      navigation: ["Inicio", "Propiedades", "Alquileres", "Ventas", "Contacto"],
      company: ["Servicios", "Equipo", "Blog"],
      Soporte: ["Preguntas Frecuentes", "Contacto", "Ayuda"],
    };
  },
};
</script>

<style scoped>
.footer-title {
  position: relative;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  color: var(--white-color);
  font-weight: 600;
}

.footer-link {
  color: var(--grey-color);
  text-decoration: none;
  transition: color 0.3s ease;
  display: block;
}

.footer-link:hover {
  color: var(--white-color-2);
}

.social-links {
  display: flex;
  gap: 0.75rem;
}

.social-icon {
  color: var(--white-color);
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: var(--grey-color);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .social-links {
    flex-direction: row;
    justify-content: center;
    gap: 1.5rem;
  }

  .footer-title {
    text-align: center;
    margin-top: 2rem;
  }

    .footer-link {
        text-align: center;
    }
}
</style>
