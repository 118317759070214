<template>
    <section class="container-fluid d-flex justify-content-center py-5">
        <div class="container cta-section d-flex justify-content-center align-items-center rounded-3 px-3">
            <div class="text bg-white rounded-2 p-5 text-center my-5" style="max-width: 600px;">
                <h2 class="fs-2">¿Estás listo para encontrar tu hogar ideal?</h2>
                <p class="lead fs-6">
                    ¡Comienza a buscar tu hogar ideal hoy! Encuentra la propiedad que se ajuste a tus necesidades y gustos.
                </p>

                <a href="/demo/propiedades" class="btn btn-primary btn-lg rounded-pill px-4 py-3 fw-semibold">
                    <i class="fas fa-search me-2"></i>Buscar Propiedades
                </a>
            </div>
        </div>
      </section>
</template>

<script>
export default {
  name: 'CTABanner'
}
</script>

<style scoped>
.cta-section {
  background-image: url('../static/images/patio.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.cta-section .text {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

@media (max-width: 991.98px) {
  .cta-wrapper {
    text-align: center;
  }
}
</style>