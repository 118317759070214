<template>
  <AppNavbar />
    <div class="calendar-landing px-3 py-5">
      <!-- Hero Section con diseño escalonado -->
      <section class="hero position-relative overflow-hidden py-6">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 hero-content">
              <span class="badge bg-primary-subtle text-primary mb-3">
                Nuevo Sistema de Agenda
              </span>
              <h1 class="display-4 fw-bold text-dark mb-4">
                Tu CRM Inmobiliario<br />
                <span class="text-primary">con Google Calendar</span>
              </h1>
              <p class="lead mb-4 text-muted">
                Sincroniza tus visitas, reuniones y cierres de venta en tiempo real. 
                Gestiona tu agenda inmobiliaria de forma profesional.
              </p>
              <button class="btn btn-primary">Comenzar Gratis</button>
            </div>
            <div class="col-lg-6 position-relative">
              <div class="hero-image-wrapper">
                <img 
                  src="@/assets/illustrations/calendar.svg" 
                  alt="Calendar Preview" 
                  class="hero-image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <!-- Características Principales -->
      <section class="features py-6 bg-light">
        <div class="container">
          <div class="text-center mb-5">
            <h2 class="fw-bold">Todo lo que necesitas para tu agenda</h2>
            <p class="lead text-muted">Funcionalidades diseñadas para el sector inmobiliario</p>
          </div>
          <div class="row g-4">
            <div v-for="feature in features" :key="feature.id" class="col-md-6 col-lg-4">
              <div 
                class="feature-card"
                :class="{ 'feature-card-highlighted': feature.highlighted }"
              >
                <div class="feature-icon">
                  <i :class="feature.icon"></i>
                </div>
                <h3>{{ feature.title }}</h3>
                <p>{{ feature.description }}</p>
                <ul class="feature-list" v-if="feature.items">
                  <li v-for="item in feature.items" :key="item">
                    <i class="fas fa-check text-primary me-2"></i>{{ item }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <!-- Sección de Integración -->
      <section class="integration py-6">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 position-relative">
              <img 
                src="@/assets/images/crm_screenshots/calendar-ss.jpg" 
                alt="Sincronización" 
                class="integration-image mb-5 mb-lg-0"
              />
            </div>
            <div class="col-lg-6">
              <h2 class="mb-4">Sincronización Bidireccional</h2>
              <div class="integration-features">
                <div v-for="item in integrationFeatures" :key="item.id" class="integration-item">
                  <div class="integration-icon">
                    <i :class="item.icon"></i>
                  </div>
                  <div class="integration-content">
                    <h4>{{ item.title }}</h4>
                    <p>{{ item.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CTABanner />
    </div>
    <AppFooter />
  </template>

  <script>
    import AppNavbar from '@/components/Navbar.vue'
    import AppFooter from '@/components/Footer.vue'
    import CTABanner from '@/components/CTABanner.vue'
    export default{
      name: 'GoogleCalendar',
      components: {
        CTABanner,
        AppNavbar,
        AppFooter
      }
    }
  </script>
  
  <script setup>
  import { ref } from 'vue'
  
  const features = ref([
    {
      id: 1,
      icon: 'fas fa-sync-alt fa-2x text-primary',
      title: 'Sincronización Total',
      description: 'Mantén tu agenda actualizada en todos tus dispositivos.',
      highlighted: true,
      items: [
        'Sincronización en tiempo real',
        'Múltiples calendarios',
        'Respaldo automático'
      ]
    },
    {
      id: 2,
      icon: 'fas fa-bell fa-2x text-primary',
      title: 'Sistema de Recordatorios',
      description: 'Notificaciones inteligentes para cada tipo de evento.',
      items: [
        'Alertas personalizadas',
        'Recordatorios SMS',
        'Notificaciones push'
      ]
    },
    {
      id: 3,
      icon: 'fas fa-users fa-2x text-primary',
      title: 'Gestión de Equipo',
      description: 'Coordina las agendas de todos tus agentes inmobiliarios.',
      items: [
        'Vista de equipo',
        'Asignación de citas',
        'Control de disponibilidad'
      ]
    }
  ])
  
  const integrationFeatures = ref([
    {
      id: 1,
      icon: 'fas fa-sync fa-lg text-primary',
      title: 'Sincronización Automática',
      description: 'Los cambios se reflejan instantáneamente en todas las plataformas.'
    },
    {
      id: 2,
      icon: 'fas fa-lock fa-lg text-primary',
      title: 'Seguridad Garantizada',
      description: 'Tus datos están protegidos con los más altos estándares de seguridad.'
    },
    {
      id: 3,
      icon: 'fas fa-mobile-alt fa-lg text-primary',
      title: 'Acceso Móvil',
      description: 'Gestiona tu agenda desde cualquier dispositivo en cualquier momento.'
    }
  ])
  </script>
  
  <style scoped>
  .py-6 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  
  .hero {
    background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
  }
  
  .hero-image-wrapper {
    position: relative;
    padding: 2rem;
  }
  
  .hero-image {
    width: 100%;
    border-radius: 1rem;
  }
  
  .floating-card {
    position: absolute;
    background: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    gap: 1rem;
    animation: float 3s ease-in-out infinite;
  }
  
  .card-1 {
    top: 10%;
    right: -10%;
  }
  
  .card-2 {
    bottom: 10%;
    left: -10%;
  }
  
  .feature-card {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    height: 100%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  }
  
  .feature-card-highlighted {
    border: 2px solid var(--primary-color);
  }
  
  .feature-icon {
    margin-bottom: 1.5rem;
  }
  
  .feature-list {
    list-style: none;
    padding: 0;
    margin-top: 1rem;
  }
  
  .feature-list li {
    margin-bottom: 0.5rem;
  }
  
  .integration-image {
    width: 100%;
    border-radius: 1rem;
    box-shadow: 0 20px 40px rgba(0,0,0,0.1);
  }
  
  .integration-item {
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .integration-icon {
    background: var(--bs-primary-bg-subtle);
    padding: 1rem;
    border-radius: 0.5rem;
  }
  
  @keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
  }
  
  @media (max-width: 991.98px) {
    .hero-content {
      text-align: center;
      margin-bottom: 3rem;
    }
    
    .stats {
      justify-content: center;
    }
    
    .floating-card {
      display: none;
    }
  }
  </style>